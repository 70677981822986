/* You can add global styles to this file, and also import other style files */


// Icons
@import url("https://i.icomoon.io/public/5287269068/ngx-feng/style.css");

// Fonts
// Montserrat
@font-face {
  font-family: Montserrat-Light;
  src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
}
@font-face {
    font-family: Montserrat;
    src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
}
@font-face {
    font-family: Montserrat-bold;
    src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Montserrat/Montserrat-ExtraBold.ttf") format("truetype");
    font-style: bold;
    font-weight: 900;
}
@font-face {
    font-family:Montserrat-SemiBold;
    src: url("https://ngx-feng.s3-sa-east-1.amazonaws.com/fonts/Montserrat/Montserrat-SemiBold.ttf") format("truetype");
    font-weight: 700;
}
@font-face {
  font-family: "Titillium Web Black";
  src: url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/titillium-web.black.ttf") format("truetype"),
    url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/titillium-web.black.woff") format("woff"),
    url("https://vasco-gs.s3.amazonaws.com/prod/assets/fonts/titillium-web.black.woff2") format("woff2");
}
@font-face {
  font-family: GillSansMT;
  src: url("https://fluminensefc.s3.amazonaws.com/prod/assets/fonts/GillSans-MT.ttf") format("truetype");
}

@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Work+Sans&display=swap');

// Angular Material Theme
@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import '@angular/material/prebuilt-themes/indigo-pink.css';

// Libs imports always last
@import '~@fengbrasil/ngx-fengst-ui/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-auth/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-layout/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-home/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-events/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-account/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-exclusive-content/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-contact/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-partner/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-experience/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-checkout/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-xray/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-security/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-landing/assets/dist/styles';
@import '~@fengbrasil/ngx-fengst-census/assets/dist/styles';

.event-coupon a {
  line-break: anywhere;
}

.event-coupon__faq {
  max-width: 300px;

  .mat-expansion-panel-header {
    height: 100px !important;
    font-size: 14px;
  }
  
  .mat-expansion-panel-header-title{
    text-align: left !important;
  }
  
  .mat-expansion-panel-header-description{
    text-align: left !important;
  }
}

@include respond-to('device') {
  .event-coupon__faq {
    max-width: 100%;

    .mat-expansion-panel-header {
      height: 80px !important;
      font-size: 16px;
    }
  }
}